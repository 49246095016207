@import 'assets/scss/custom/_variables.scss';

.partnerDetailsTableWrapper {
  overflow-x: auto;
  .viewedStories {
    .filterOption {
      width: 300px;
    }
    .headerBadge {
      margin-left: 5px;
      font-size: 10px;
      padding: 4px 5px;
      border-radius: 11px;
      background-color: #aaedf9;
      color: #03acca;
    }
    .in_progress_type {
      max-height: 352px !important;
    }
    .needs_attention_type {
      max-height: 352px !important;
    }
    .disqualified_type {
      max-height: 420px !important;
      table {
        tfoot {
          display: none;
        }
        tbody {
          max-height: 295px !important;
        }
      }
    }
  }
  .closed_type {
    max-height: 352px !important;
  }
  .cardBody {
    .tableWrapper {
      overflow-x: auto;
      border-radius: 6px;
      max-height: 250px;
      overflow-y: scroll;
      .active_referrals {
        border-left: 5px solid #2c90ec;
      }
      .responded {
        border-left: 5px solid #9f5ca5;
      }
      .qualified {
        border-left: 5px solid #e2e387;
      }
      .disqualified {
        border-left: 5px solid #eb6363;
      }
      .silent {
        border-left: 5px solid #bdbdbd;
      }
      .untouched {
        border-left: 5px solid #2c90ec;
      }
      .contacted {
        border-left: 5px solid #32325d;
      }
      .closed {
        border-left: 5px solid #6fcf97;
      }
      .on-hold {
        border-left: 5px solid #f2994a;
      }
      .addIcon {
        color: #bdbdbd;
        cursor: pointer;
      }

      table {
        min-width: 575px;
        border-bottom: 0px !important;
        * {
          td {
            vertical-align: middle;
          }
        }
        thead {
          height: 44px;
          width: calc(100% - 1em);
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 0;
          tr {
            display: table;
            width: 100%;
            table-layout: fixed;
            th {
              background-color: #e8e8e8;
              font-weight: 700;
              font-size: 12px;
              color: #000000;
              text-align: left;
            }
          }
        }
        tfoot {
          height: 44px;
          width: calc(100% - 1em);
          position: -webkit-sticky;
          position: sticky;
          bottom: 0;
          inset-block-end: 0;
          tr {
            display: table;
            width: 100%;
            table-layout: fixed;
            th {
              padding: 8.5px 20px;
              text-align: left;
              font-weight: 700;
              font-size: 14px;
              line-height: 30px;
              background-color: #e8e8e8;
              color: #000000;
            }
          }
        }
        tbody {
          tr {
            display: table;
            width: 100%;
            table-layout: fixed;
            td {
              padding: 8.5px 24px;
              text-align: left;
              font-weight: 400;
              font-size: 14px;
              line-height: 30px;
            }
          }
        }
        .row {
          cursor: pointer;
        }
        .storyName {
          width: fit-content;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: unset;
        }
      }
      .storyPriority {
        padding: 2px 8px;
        border-radius: 11px;
        font-size: 10px;
        font-weight: 600;
      }
      .storyPriorityLow {
        color: #1aae6f;
        background-color: #b0eed3;
      }
      .storyPriorityHigh {
        color: #f80031;
        background-color: #fdd1da;
      }
      .storyPriorityMedium {
        color: #fb6340;
        background-color: #ffe3b8;
      }
      .priorityHeader {
        width: 6.5rem;
      }
      .epicBadge {
        border-radius: 10rem !important;
        max-width: 100% !important;
        transition: all 0.3s;
        cursor: default;
        .closeIcon {
          display: none;
        }
      }
      .epicName {
        overflow: hidden !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical !important;
        color: $gray-700;
        overflow-wrap: break-word;
        word-break: break-word;
        word-wrap: break-word;
        hyphens: auto;

        h5 {
          color: $gray-700;
        }
      }
    }
  }
}

.partnerDashboard {
  .header {
    margin-top: 17.5px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .title {
      font-weight: 700;
      font-size: 26px;
      line-height: 35px;
      /* Primary text (32325D) */
      color: #32325d;
    }
    .dropdownWrapper {
      display: flex;
      flex-direction: column;
      .dropdownLabel {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #32325d;
        margin-bottom: 7px;
      }
      .customDropdown {
        :global {
          .dropdown-item {
            color: #525f7f;
          }
          .dropdown-item.active {
            font-weight: 600;
            color: #525f7f;
          }
        }
        button {
          margin-left: 0 !important;
          border: 1px solid #32325d !important;
          background-color: white !important;
          color: black !important;
        }
      }
      .caret {
        span {
          margin-left: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
        i {
          color: white;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          width: 30px;
          justify-content: center;
          background: #32325d;
        }
      }
      div {
        button:first-child {
          margin-right: 1rem;
          background: #0ca8eb;
          color: #ffffff;
          border: none;
          width: 175px;
          text-align: inherit;

          @media screen and (max-width: 490px) {
            margin-bottom: 1rem;
          }
        }

        button {
          cursor: pointer;
          width: fit-content;
          padding: 13px 0;
          height: 48px;
          border: 1px solid #525f7f;
          /* Input Form Controls/Default */
          filter: drop-shadow(0px 3px 2px rgba(233, 236, 239, 0.05));
          border-radius: 4px;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;

          background-color: transparent;
        }
      }
    }
  }

  .tables {
    @media (max-width: 1484px) {
      grid-template-columns: 1fr;
    }
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 827px) {
      display: block;
    }
  }

  .chartWrapper {
    position: relative;
    display: grid;
    margin-bottom: 48px;
    grid-template-columns: 1fr 2fr;
    background: #ffffff;
    border-radius: 6px;
    padding: 22px 40px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      padding-bottom: 50px;
      gap: 24px;
      padding: 22px 10px 35px 10px;
    }

    filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
      drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
    .lineChart {
      height: 180px;
      canvas {
        width: 100% !important;
      }
    }
    .pieChart {
      canvas {
        margin: auto;
        @media screen and (max-width: 354px) {
          width: 100% !important;
        }
      }
    }
  }

  .infoWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 2rem;
    div {
      min-width: 213px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      background: #ffffff;
      border-radius: 6px;
      padding: 20px;
      filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
        drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));

      cursor: pointer;
      @media screen and (max-width: 531px) {
        min-width: 100%;
      }
      p {
        margin-bottom: 0;
      }
      p:first-child {
        font-weight: 600;
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        /* Card Heading (32325D) */
        color: #32325d;
        width: 117px;
      }
      p + p {
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        /* identical to box height, or 156% */
        text-align: right;
        width: 82px;
        /* Card Heading (32325D) */
        color: #32325d;
      }

      div:last-child {
        margin-right: 0;
      }
    }
  }
}

.meetingModalWrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  .meetingDescription {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    .header {
      margin: 60px auto;
      text-align: center;

      img {
        width: 150px;
      }
    }
    .content {
      padding: 24px 32px;
      h1 {
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 22px;
        line-height: 16px;
        color: #000000;
      }
      h5 {
        margin-bottom: 16px;
        display: flex;
        gap: 4px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #737373;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 24px;
      }
      a {
        font-size: 14px;
      }
    }
  }
  .partnerActivities {
    .partnerModalTabs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 8px auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .activeTab {
        color: #53c3f0;
      }
      .tab {
        text-align: center;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

.referralForm {
  h5 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;

    /* Text Color/Dark Blue */
    color: #525f7f;
  }
  .required::after {
    content: '*';
    margin-left: 0.5em;
    color: #ff0000;
  }

  .typeWrapper {
    display: flex;
    max-width: fit-content;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    overflow: hidden;

    .type {
      padding: 12px 24px;
      cursor: pointer;
      color: #32325d;

      &.active {
        color: white;
        background: #32325d;
      }
    }
  }
}

.referralAlert {
  h2 {
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 33px;

    color: #32325d !important;
  }

  .text {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 19px;

    color: #525f7f !important;
  }
}

.noDataWrapper {
  border-radius: 6px;
  overflow: hidden;
}

.emptyStateHeader {
  height: 47px;
  background: #e8e8e8;
  width: 100%;
  margin-bottom: 20px;
}

.noData {
  .iconWrapper {
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    opacity: 0.4;
    padding: 16px;
    border-radius: 50%;
    i {
      font-size: 25px;
    }
  }
}
.defaultText {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #cdcdcd;
}
.emptyData {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: auto;
  background-image: url('../../../../assets/img/images/Contentreferrals.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  max-width: inherit;
  .emptyDataHeading {
    font-size: 20px;
    font-weight: bold;
  }
  .emptyText {
    margin: 20px;
    line-height: 22px;
  }
}
.earningsInfoTooltip {
  background: #ffffff;
  padding: 8px 12px;
  filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
    drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #525f7f;
    text-align: left;
    margin-bottom: 0;
  }
}
.text-ellipsis {
  white-space: nowrap !important;
  overflow: 'hidden' !important;
  text-overflow: 'ellipsis' !important;
  width: 80 !important;
}
