@import 'assets/scss/custom/_variables.scss';

.formModal {
  max-width: 503px;
  .body {
    margin-right: 2px;
    padding-right: 1rem !important;
    &::-webkit-scrollbar {
      background-color: transparent;
      opacity: 0.9;
      width: 4px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      width: 4px;
    }
  }

  .header {
    border-bottom: none;
  }
  .required::after {
    content: '*';
    margin-left: 0.5em;
    color: #ff0000;
  }
  .form {
    .userRoles {
      h3 {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #322d52;
      }
      .roles {
        .role:first-child {
          border-radius: 6px 6px 0px 0px;
        }
        .role:last-child {
          border-radius: 0px 0px 6px 6px;
        }
        .role {
          overflow: hidden;
          display: flex;
          align-items: center;
          height: 100px;
          border: 1px solid #cad1d7;
          cursor: pointer;
          user-select: none;
          &.admin.active {
            border: 3px solid #525fd7;
            background-color: #f5faff;
            border-left: 1px solid #525fd7 !important;
            .tooltip {
              svg {
                circle {
                  fill: #525fd7;
                }
                path {
                  stroke: #fff !important;
                }
              }
            }
          }
          &.manager.active {
            border: 3px solid #34b1b9;
            background-color: #f0ffff;
            border-left: 1px solid #34b1b9 !important;
            .tooltip {
              svg {
                circle {
                  fill: #34b1b9;
                }
                path {
                  stroke: #fff !important;
                }
              }
            }
          }
          &.representative.active {
            border: 3px solid #d27735;
            background-color: #fff9f0;
            border-left: 1px solid #d27735 !important;
            .tooltip {
              svg {
                circle {
                  fill: #d27735;
                }
                path {
                  stroke: #fff !important;
                }
              }
            }
          }

          &.admin {
            .icon {
              background-color: #525fd7;
              svg {
                width: 56.67px;
                height: 68px;
              }
            }
            .content {
              .details {
                h3 {
                  color: #525fd7;
                }
              }
              .tooltip {
                svg {
                  circle {
                    stroke: #525fd7;
                  }
                  path {
                    stroke: #525fd7;
                  }
                }
              }
            }
          }
          &.manager {
            .icon {
              background-color: #34b1b9;
              svg {
                width: 56px;
                height: 65px;
              }
            }
            .content {
              .details {
                h3 {
                  color: #34b1b9;
                }
              }
              .tooltip {
                svg {
                  circle {
                    stroke: #34b1b9;
                  }
                  path {
                    stroke: #34b1b9;
                  }
                }
              }
            }
          }
          &.representative {
            .icon {
              background-color: #d27735;
              svg {
                width: 51px;
                height: 51px;
              }
            }
            .content {
              .details {
                h3 {
                  color: #d27735;
                }
              }
              .tooltip {
                svg {
                  circle {
                    stroke: #d27735;
                  }
                  path {
                    stroke: #d27735;
                  }
                }
              }
            }
          }

          .icon {
            padding: 12px;
            width: 74px;
            height: 98px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              height: 38px;
              width: 38px;
              path {
                fill: #fff;
              }
            }
          }
          .content {
            display: flex;
            align-items: center;
            gap: 12.5px;
            padding: 16px;
            justify-content: space-between;
            width: 100%;
            @media (max-width: 480px) {
              padding: 8px;
            }
            .details {
              h3 {
                text-transform: capitalize;
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 8px;
                @media (max-width: 480px) {
                  margin-bottom: 0px;
                }
              }
              p {
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #222529;
                margin-bottom: 0;
              }
            }
            .tooltip {
            }
          }
        }
      }
    }
    .userInfo {
      h3 {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #322d52;
        margin-bottom: 25px;
      }
      .required::after {
        content: '*';
        margin-left: 0.5em;
        color: #ff0000;
      }
      h5 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #525f7f;
      }
    }
    .supervisor {
      h2 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #322d52;
        margin-bottom: 1px;
      }
      h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #322d52;
        margin-bottom: 16px;
      }
      :global {
        .rselect__option--is-selected {
          background: #3c4d69;
          color: #fff;
        }
      }
    }
    .allowEarningView {
      h2 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #322d52;
        margin-bottom: 1px;
      }
      h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #322d52;
        margin-bottom: 16px;
      }
      .typeWrapper {
        display: flex;
        max-width: fit-content;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-bottom: 1.5rem;
        overflow: hidden;
        :first-child {
          border-right: 1px solid #d9d9d9;
        }

        .type {
          padding: 8px 20px;
          cursor: pointer;
          color: #32325d;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;

          &.active {
            color: white;
            background: #32325d;
          }
        }
      }
    }
    .note {
      h2 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #322d52;
        margin-bottom: 16px;
      }
    }
  }
}
