@import 'assets/scss/custom/_variables.scss';

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning !important;
}

.content {
  width: 40%;
  padding: 0px 18px;
  height: calc(100vh - 300px);
  overflow: auto;
  .collapseButtonWrapper {
    display: flex;
    justify-content: flex-end;
    .collapseButton {
      color: rgba(0, 0, 0, 1);
      border: none;
      margin-bottom: 8px;
      background: transparent;
      box-shadow: none;
      &:hover,
      &:focus,
      &:active {
        outline: none;
        border: none;
        box-shadow: none !important;
        background: transparent;
      }
    }
  }
  .fileValidationWrapper {
    margin-bottom: 18px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    .accordionHeaderWrapper {
      display: flex;
      gap: 16px;
      .headerContent {
        font-size: 14px;
        font-weight: 600;
        color: #32325d;
        display: flex;
        align-items: center;
      }
      .selectedOption {
        padding: 3px 8px 3px 8px;
        border-radius: 6px;
        border: 1px solid #2dce89;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        background-color: #ffffff;
      }
    }
    .description {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: #32325d;
      margin-bottom: 16px;
    }
    .buttonContainer {
      display: flex;
      flex-direction: column;
      .buttonWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        gap: 7px;
        .button {
          text-align: center;
          width: 100%;
          border-radius: 4px;
          box-shadow: 0px 5px 8px 0px #0000001f;
          color: #04a0f0;
          background-color: #ffffff;
          padding: 7px;
          &.selectedButton {
            color: #ffffff;
            background-color: #1da1f2;
          }
        }
      }
      .intactButton {
        padding: 7px;
        border-radius: 4px;
        color: #3aaf85;
        border: 1px solid #3aaf85;
        box-shadow: 0px 5px 8px 0px #0000001f;
        &.selectedIntactButton {
          color: #ffffff;
          background-color: #3aaf85;
        }
      }
    }
    .customerText {
      margin-top: 40px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      color: #32325d;
      > span {
        color: #fb6440;
      }
    }
  }
  .processTaxWrapper {
    cursor: pointer;
    border-radius: 8px;
    border: 1px dashed #04a0f0;
    padding: 43px 0px;
    background: #f6f9fc;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .processTaxButton {
      background: transparent;
      box-shadow: none;
      border: none;
      margin-bottom: 12px;
    }
    .processTaxText {
      font-size: 20px;
      line-height: 30px;
      color: #32325d;
    }
  }
  .questionContainer {
    margin-bottom: 10px;
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    .newSectionBUtton {
      margin: 8px 0px;
      padding: 7px;
      border-radius: 4px;
      border: 1px solid rgba(4, 160, 240, 1);
      box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.12);
      color: rgba(4, 160, 240, 1);
      background-color: rgba(255, 255, 255, 1);
      &:hover,
      &:focus,
      &:active {
        color: rgba(4, 160, 240, 1);
        border: 1px solid rgba(4, 160, 240, 1);
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
}
