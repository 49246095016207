.questionWrapper {
  margin: 24px 0px 0px 0px;
  .question {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    color: rgba(50, 50, 93, 1);
    margin-bottom: 8px;
    .required {
      color: rgba(251, 100, 64, 1);
      margin-left: 5px;
    }
  }
  .optionsWrapper {
    display: flex;
    gap: 8px;
    .option {
      padding: 4px 10px;
      border-radius: 6px;
      border: 1px solid rgba(29, 161, 242, 1);
      color: rgba(29, 161, 242, 1);
      background-color: rgba(255, 255, 255, 1);
    }
    .disabledOption {
      background-color: #ffffff;
      border: 1px solid #8898aa;
      color: #8898aa;
      pointer-events: none;
    }
    .completedOption {
      border: 1px solid #525f7f !important;
      color: #525f7f !important;
      pointer-events: none;
    }
    .completedSelected {
      background-color: #525f7f !important;
      color: #ffffff !important;
      border: none;
      pointer-events: none;
    }
    .selectedOption {
      background-color: rgba(29, 161, 242, 1);
      color: rgba(255, 255, 255, 1);
      &.disabledSelected {
        color: #ffffff;
        background-color: #bdbdbd;
        border: none;
        pointer-events: none;
      }
    }
  }
  .invalid {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
    text-align: start;
  }
}
