@import 'assets/scss/custom/_variables.scss';
.container {
  .header {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    color: rgba(50, 50, 93, 1);

    > span {
      color: rgba(245, 54, 92, 1);
      margin-left: 5px;
    }
    margin: 24px 0px 8px 0px;
  }
  .tableContainer {
    .table {
      table {
        margin-bottom: 0px !important;
      }
    }
    .note {
      margin: 8px 0px 10px 0px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: rgba(245, 54, 92, 1);
    }
    .addOwnerShipButton {
      border-radius: 4px;
      margin: 0;
      min-width: 110px;
      padding: 8px 5px;
      line-height: 20px;
      border: 1px solid #000;
      background: #fff;
      color: #525f7f;
      font-size: 12px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.4px;
    }
    .dropDownText {
      > span {
        color: #000000;
        &:hover,
        &:focus,
        &:active {
          color: #5e72e4;
        }
      }
    }
  }
}

.inputWrapper {
  margin-bottom: 24px;
  .label {
    margin-top: 0px;
    color: rgba(23, 43, 77, 1);
  }
  .label::after {
    content: '*';
    margin-left: 1px;
    color: #fb6440;
  }
  .percentageWrapper {
    margin-top: 24px;
    display: flex;
    align-items: flex-end;
    gap: 16px;
    .input {
      width: 100%;
    }
  }
}
.buttonContainer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  .cancel {
    color: #1da1f2;
    outline: none;
    border: none;
    box-shadow: none !important;
    background: transparent;
    &:hover,
    &:focus,
    &:active {
      color: #1da1f2;
      outline: none;
      border: none;
      box-shadow: none !important;
      background: transparent !important;
    }
  }
  .add {
    background-color: #1da1f2;
    color: #ffffff;
    box-shadow: 0px 5px 8px 0px #0000001f;
    &:hover,
    &:focus,
    &:active {
      background-color: #1da1f2;
      color: #ffffff;
      outline: none;
      border: none;
      box-shadow: 0px 5px 8px 0px #0000001f;
    }
    &.disabled {
      color: #cfd1d8;
      background-color: #e8eaef;
      box-shadow: none !important;
      &:hover,
      &:focus,
      &:active {
        color: #cfd1d8;
        background-color: #e8eaef;
        outline: none;
        border: none;
        box-shadow: none !important;
      }
    }
  }
}
.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning !important;
  text-align: start;
}
