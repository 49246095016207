.bodyWrapper {
  .content {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: #000000;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    .cancel {
      color: #1da1f2;
      outline: none;
      border: none;
      box-shadow: none !important;
      background: transparent;
      &:hover,
      &:focus,
      &:active {
        color: #1da1f2;
        outline: none;
        border: none;
        box-shadow: none !important;
        background: transparent !important;
      }
    }
    .confirm {
      padding: 11px 16px;
      border-radius: 4px;
      color: #ffffff;
      background-color: #ec0c38;
      box-shadow: 0px 5px 8px 0px #0000001f;
      &:hover,
      &:focus,
      &:active {
        padding: 11px 16px;
        border-radius: 4px;
        color: #ffffff;
        background-color: #ec0c38;
        box-shadow: 0px 5px 8px 0px #0000001f;
      }
    }
  }
}
