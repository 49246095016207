.noDataIndication {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  padding: 30px;
  .p {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
}
.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .yearsWrapper {
    display: flex;
    align-items: center;
    gap: 36px;
    .years {
      display: flex;
      gap: 8px;
      .editBtn {
        //font-family: 'Inter';
        font-size: 12px;
        color: #0ca8eb;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .uploadDocButton {
    border-radius: 4px;
    margin: 0;
    min-width: 110px;
    padding: 8px 5px;
    line-height: 20px;
    border: 1px solid #000;
    background: #fff;
    color: #525f7f;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
  }
}
.badge {
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  margin-right: 4px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.42px;
  background-color: #ffbeae;
  color: #d42900;
  &.rounded {
    border-radius: 15px;
    padding: 5px 10px;
  }
  &.success {
    color: #24a46d;
    background: #b0eed3;
  }
  &.info {
    color: #fff;
    background-color: #35b0f0;
  }
  &.notStarted {
    color: #525f7f;
    background-color: #d8d8d8;
  }
}
.tableHeader {
  max-height: calc(100vh - 243px);
  overflow-y: auto;
  .fileName {
    span {
      color: #525f7f;
      font-size: 13px;
      font-weight: 700;
      font-family: Open Sans;
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .status {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 600;
    &.danger {
      color: #f5365c;
    }
    &.success {
      color: #24a46d;
    }
    &.info {
      color: #1da1f2;
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :global {
    .table {
      border-bottom: 0;
    }
    .react-bs-table-no-data {
      width: 100% !important;
    }

    .table th {
      padding: 16px;
      vertical-align: baseline;
    }

    .table td {
      vertical-align: middle;
      color: #8898aa;
      font-family: Open Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    tbody,
    thead {
      display: block;
      width: 100%;
    }
    table {
      display: block;
    }
    tr {
      display: flex;
      width: 100%;
    }
    td:nth-child(1) {
      width: 40%;
    }
    td:nth-child(2) {
      width: 15%;
    }
    td:nth-child(3) {
      width: 20%;
    }
    td:nth-child(4) {
      width: 25%;
    }
    th:nth-child(1) {
      width: 40%;
    }
    th:nth-child(2) {
      width: 15%;
    }
    th:nth-child(3) {
      width: 20%;
    }
    th:nth-child(4) {
      width: 25%;
    }

    .table tr {
      .fileName {
        overflow: hidden;
        color: #525f7f;
        text-overflow: ellipsis;
        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .table .table-data {
      min-height: 80px;
    }

    .table tbody tr td {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 0px;
      cursor: pointer;
      padding-right: 0px;
    }

    .table tbody tr :first-child {
      padding-left: 10px;
    }

    .table tbody tr :last-child {
      padding-right: 10px;
      // max-width: 350px;
      // overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media (max-width: 1200px) {
        // max-width: 250px;
      }
    }
  }
}

.cursor {
  cursor: pointer;
}
.badgeWrapper {
  &:first-child {
    span {
      margin-left: 4px;
    }
  }
}
