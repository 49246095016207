.circularProgressContainer {
  position: relative;
  width: 50px;
  height: 50px;
}

.progressCircle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.5s ease; /* Adjust the duration as needed */
}
