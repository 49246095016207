.card {
  margin-bottom: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), -3px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }

  .role {
    padding: 18px 18px 24px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.admin {
      background-color: #f5faff;
      .content {
        .title {
          h4 {
            color: #525fd7;
          }
        }
      }
      .action {
        .inviteButton {
          background-color: #525fd7;
        }
      }
    }
    &.manager {
      background-color: #f0ffff;
      .content {
        .title {
          h4 {
            color: #34b1b9;
          }
        }
      }
      .action {
        .inviteButton {
          background-color: #34b1b9;
        }
      }
    }
    &.representative {
      background-color: #fff9f0;
      .content {
        .title {
          h4 {
            color: #d27735;
          }
        }
      }
      .action {
        .inviteButton {
          background-color: #d27735;
        }
      }
    }
    .content {
      .title {
        display: flex;
        align-items: center;
        gap: 14px;
        margin-bottom: 20px;
        h4 {
          text-transform: capitalize;
          margin-bottom: 0;
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
        }
      }
      .privileges {
        h5 {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #322d52;
          text-transform: capitalize;
          i {
            margin-right: 5px;
            color: #52d75f;
          }
        }
        .list {
          li {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #322d52;
          }
        }
      }
      .prohibitions {
        h5 {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #322d52;
          text-transform: capitalize;
          i {
            margin-right: 5px;
            color: #f05353;
          }
        }
        .list {
          li {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #322d52;
          }
        }
      }
    }
    .action {
      text-align: center;
      .inviteButton {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        border: none;
        padding: 8px 16px;
      }
    }
  }
}
