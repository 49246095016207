@import 'assets/scss/custom/_variables.scss';

.wrapper {
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    margin-top: 32px;
    margin-bottom: 32px;
    /* Primary text (32325D) */
    color: #32325d;
  }
  .clientHeaderWrapper {
    display: flex;
    gap: 24px;
    @media screen and (max-width: 480px) {
      display: block;
    }
    p {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    .searchLabel {
      color: #32325d;
    }
    .searchButtonWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 480px) {
        width: 80%;
        margin-bottom: 20px;
      }
      button {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
      }
      .input {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #cdcdcd;
      }
    }
  }
  .headerDropdowns {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
    div {
      p {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        color: #32325d;
      }
    }

    .customDropdown {
      :global {
        .dropdown-menu {
          z-index: 999999;
        }
        .dropdown-item {
          color: #525f7f;
        }
        .dropdown-item.active {
          font-weight: 600;
          color: #525f7f;
        }
      }
      button {
        margin-left: 0 !important;
        border: 1px solid #32325d !important;
        background-color: white !important;
        color: black !important;
      }
    }
    .caret {
      span {
        margin-left: 10px;
        color: #32325d;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
      .statusText {
        color: #525f7f;
      }
      i {
        color: white;
        position: absolute;
        right: -1px;
        top: -1px;
        height: 102%;
        display: flex;
        align-items: center;
        width: 30px;
        justify-content: center;
        background: #32325d;
        border-radius: 0px 4px 4px 0px;
      }
    }
    div {
      button:first-child {
        margin-right: 1rem;
        background: #0ca8eb;
        color: #ffffff;
        border: none;
        width: 210px;
        text-align: inherit;

        @media screen and (max-width: 490px) {
          margin-bottom: 1rem;
        }
      }

      button {
        cursor: pointer;
        width: fit-content;
        padding: 13px 0;
        height: 48px;
        border: 1px solid #525f7f;
        /* Input Form Controls/Default */
        filter: drop-shadow(0px 3px 2px rgba(233, 236, 239, 0.05));
        border-radius: 4px;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;

        background-color: transparent;
      }
    }
  }
}
.emptyData {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: auto;
  background-image: url('../../../assets/img/images/Contentreferrals.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  max-width: inherit;
  .emptyDataHeading {
    font-size: 20px;
    font-weight: bold;
  }
  .emptyText {
    margin: 20px;
    line-height: 22px;
  }
}
