.wrapper {
  margin-top: 16px;
  .header {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 16px;
    @media (max-width: 715px) {
      flex-direction: column;
      align-items: baseline;
      gap: 24px;
    }
    p {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #322d52;
    }
    .inviteTeamMember {
      font-family: 'Open Sans';
      font-weight: 800;
      font-size: 16px;
      color: #324cdd !important;
      line-height: 22px;
      text-align: left;
      color: #32325d;
      margin-bottom: 20px;
    }
    .inviteTeamButton {
      padding: 0;
      margin-right: 32px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #324cdd;
      margin-bottom: 8px;
      :global {
        .btn-inner--text {
          margin-left: 5px !important;
        }
      }
      span {
        i {
          font-size: 9px;
          top: -2px;
        }
      }
    }
    .viewPermissionButton {
      &.active {
        font-weight: 700;
      }
      padding: 0;
      margin-right: 32px;
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #2c90ec;
      &:focus:active {
        box-shadow: none;
      }
      span {
        i {
          top: 0;
        }
      }
    }
    .viewPermissionButton {
      padding: 0;
      margin-right: 32px;
    }
  }
}

.tooltipRole {
  padding: 18px 18px 24px 18px;

  &.admin {
    background-color: #f5faff;
    .content {
      .title {
        h4 {
          color: #525fd7;
        }
      }
    }
    .action {
      .inviteButton {
        background-color: #525fd7;
      }
    }
  }
  &.manager {
    background-color: #f0ffff;
    .content {
      .title {
        h4 {
          color: #34b1b9;
        }
      }
    }
    .action {
      .inviteButton {
        background-color: #34b1b9;
      }
    }
  }
  &.representative {
    background-color: #fff9f0;
    .content {
      .title {
        h4 {
          color: #d27735;
        }
      }
    }
    .action {
      .inviteButton {
        background-color: #d27735;
      }
    }
  }
  .content {
    .title {
      display: flex;
      align-items: center;
      gap: 14px;
      margin-bottom: 20px;
      h4 {
        text-transform: capitalize;
        margin-bottom: 0;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
      }
    }
    .privileges {
      h5 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #322d52;
        i {
          margin-right: 5px;
          color: #52d75f;
        }
      }
      .list {
        li {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #322d52;
        }
      }
    }
    .prohibitions {
      h5 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #322d52;
        i {
          margin-right: 5px;
          color: #f05353;
        }
      }
      .list {
        li {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #322d52;
        }
      }
    }
  }
}

.tooltip {
  :global {
    .tooltip {
      opacity: 1;
      .tooltip-inner {
        max-width: 272px;
        text-align: left;
        padding: 0;
        box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15),
          -1px 4px 5px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        overflow: hidden;
      }
    }
  }
}

.popover {
  max-width: 944px;
}
.popperClassName {
  max-width: 944px;
  @media (max-width: 715px) {
    height: 366px;
    overflow: auto;
  }
}
