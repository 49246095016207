.modalWrapper {
  max-width: 503px;
  .memberDetails {
    margin-bottom: 32px;
    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #322d52;
      margin-bottom: 16px;
    }
    .details {
      padding-left: 8px;
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #3c4d69;
        margin-bottom: 0;
      }
    }
  }
  .supervisorDetails {
    margin-bottom: 32px;
    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #322d52;
      margin-bottom: 4px;
    }
    .details {
      padding-left: 8px;
      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3c4d69;
        margin-bottom: 0;
      }
    }
  }
  .assignedRole {
    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #322d52;
      margin-bottom: 8px;
    }
    .role {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.admin {
        background-color: #f5faff;
        .content {
          .title {
            h4 {
              color: #525fd7;
            }
          }
        }
        .action {
          .inviteButton {
            background-color: #525fd7;
          }
        }
      }
      &.manager {
        background-color: #f0ffff;
        .content {
          .title {
            h4 {
              color: #34b1b9;
            }
          }
        }
        .action {
          .inviteButton {
            background-color: #34b1b9;
          }
        }
      }
      &.representative {
        background-color: #fff9f0;
        .content {
          .title {
            h4 {
              color: #d27735;
            }
          }
        }
        .action {
          .inviteButton {
            background-color: #d27735;
          }
        }
      }
      .content {
        .title {
          display: flex;
          align-items: center;
          gap: 14px;
          margin-bottom: 20px;
          h4 {
            text-transform: capitalize;
            margin-bottom: 0;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
          }
        }
        .abilities {
          display: flex;
        }
        .privileges {
          h5 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #322d52;
            text-transform: capitalize;
            i {
              margin-right: 5px;
              color: #52d75f;
            }
          }
          .list {
            padding-left: 21px;
            li {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #322d52;
            }
          }
        }
        .prohibitions {
          h5 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #322d52;
            text-transform: capitalize;
            i {
              margin-right: 5px;
              color: #f05353;
            }
          }
          .list {
            padding-left: 21px;
            li {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #322d52;
            }
          }
        }
      }
      .action {
        text-align: center;
        .inviteButton {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          border: none;
          padding: 8px 16px;
        }
      }
    }
  }
  .modalFooter {
    .back {
      padding: 0;
      box-shadow: none !important;
      background: transparent;
      &:active {
        background-color: transparent;
      }
      &:hover {
        transform: none;
      }

      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #4f4f4f;
        i {
          top: 0;
        }
      }
    }
  }
}
