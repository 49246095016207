@import 'assets/scss/custom/_variables.scss';
.team-view {
  :global {
    .react-bootstrap-table {
      table {
        th {
          border-top: 0;
          font-size: 10.56px;
          font-weight: 600;
          color: #8898aa;
        }

        td {
          font-size: 13px !important;
          font-weight: 600;
        }

        .reset-expansion-style {
          padding: 0;
          td {
            font-weight: 400;
          }
        }
        .first-expand-cell {
          width: 56px;
          display: block;
        }
        .expand-name-cell {
          width: 50%;
          padding-left: 2.1rem;
        }
        .expand-role-cell {
          width: 25%;
          padding-left: 1.2rem;
        }
        .expand-status-cell {
          width: 25%;
        }
      }
    }
  }
}
