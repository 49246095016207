@import 'assets/scss/custom/_variables.scss';

.modalWrapper {
  :global {
    .modal-content {
      width: 925px;
      height: 766px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-self: center;
      margin-left: -200px;
      justify-content: center;
    }
  }
}

.modalWrapper2 {
  :global {
    .modal-content {
      width: fit-content;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-self: center;
    }
  }
}
.modalWrapper2 {
  :global {
    .modal-content {
      width: fit-content;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-self: center;
    }
  }
}

.modalWrapper1 {
  :global {
    .modal-content {
      width: 681px;
      height: 304px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
    }
  }
}

.modalWrapper5 {
  :global {
    .modal-content {
      width: 681px;
      height: 325px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
    }
  }
}

.title {
  font-weight: 700;
  width: 681px;
  font-size: 42px;
  color: $gray-800;
  line-height: 52px;
  font-family: 'Nunito Sans';
}

.description {
  font-size: 20px;
  color: $gray-700;
  margin: 16px 0 32px 0;
  text-align: start;
  font-family: 'Nunito Sans';
}

.btn {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.imgContainer {
  margin: 20px;
  width: 860px;
  height: 440px;
}
