.inviteTeamWrapper {
  min-height: calc(100vh - 215px);
  display: flex;
  align-items: center;
  justify-content: baseline;

  .content {
    margin-top: 154px;
    max-width: 593px;
    h4 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #32325d;
      margin-bottom: 4px;
    }
    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #32325d;
      margin-bottom: 20px;
    }
    .inviteButton {
      padding: 11px 49px;
    }
  }
}
