.modalWrapper {
  max-width: 992px;
  .header {
    border-bottom: none;
    h3 {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #322d52;
      margin-bottom: 0;
    }
    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #32325d;
      margin-bottom: 0;
    }
  }
}
