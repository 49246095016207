.nav {
  margin-bottom: 25px;
  gap: 20px;
  .navItem {
    .navLink {
      cursor: pointer;
      color: #32325d;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0.25rem 0;
      &:hover {
        border: 1px solid transparent;
      }
      &.active {
        font-weight: 700;
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom: 4px solid#08A5EF;
        background-color: transparent;
      }
    }
  }
}
