label {
  color: #32325d;
  font-family: Open Sans;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 8px;
}
.loading {
  position: absolute;
  inset: 0;
  z-index: 999;
  background-color: white;
  opacity: 0.5;
}
.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
  text-align: start;
}
.form-group {
  margin-bottom: 0px;
}
.completed {
  .form-group {
    margin-bottom: 0px;
    > input {
      pointer-events: none !important;
    }
  }
}
.completed {
  > button {
    pointer-events: none !important;
    background-color: red !important;
  }
}

:global {
  .form-control-label {
    color: #172b4d !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    margin-top: 24px;
    margin-bottom: 8px;
  }
}

.group {
  > label {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 25px;
    color: #32325d !important;
    font-style: normal;
    margin-bottom: 0px !important;
    margin-top: 35px !important;
  }
}

.timeline-group {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 24px;
    height: calc(100% - 2px);
    border-left: 2px dashed #d5d8de;
    display: flex;
  }
  &.title {
    label {
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 20px;
        top: 30px;
        width: 10px;
        height: 10px;
        background-color: #32325d;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
      }
    }
  }
}

.timeline-group:not(:has(+ .timeline-group)) {
  &::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 24px;
    height: calc(100% - 30px);
    border-left: 2px dashed #d5d8de;
    display: flex;
  }
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 20px;
    bottom: 0;
    width: 10px;
    height: 10px;
    background-color: #d8d8d8;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }
}

.inline-group {
  display: inline-grid;
  width: 50%;
  padding-right: 20px;
  label {
    margin: 8px 0px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 19px;
    color: #172b4d !important;
  }
}

.critical_info_label {
  label::before,
  label::after {
    content: '**';
    color: red;
  }
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
  text-align: start;
}

.wrapper {
  border: 1px solid rgba(233, 236, 239, 1);
  border-radius: 8px;
  .accordionHeaderWrapper {
    display: flex;
    gap: 8px;

    .returnYear {
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
    }
    .hasStarted {
      padding: 3px 12px;
      border-radius: 12px;
      border: 1px solid rgba(236, 12, 56, 1);
      color: rgba(236, 12, 56, 1);
      width: fit-content;
      background-color: rgba(255, 255, 255, 1);
    }
    .taxReturnType {
      padding: 3px 8px;
      border-radius: 100px;
      border: 1px solid rgba(17, 205, 239, 1);
      color: rgba(0, 0, 0, 1);
      width: fit-content;
    }
    .inProgress {
      padding: 3px 12px;
      border-radius: 12px;
      border: 1px solid rgba(35, 61, 211, 1);
      color: rgba(35, 61, 211, 1);
      width: fit-content;
    }
    .completed {
      padding: 3px 12px;
      border-radius: 12px;
      width: fit-content;
      color: #24a46d !important;
      border: 1px solid #24a46d !important;
    }
  }
}

.buttonContainer {
  padding-top: 50px;
  margin: 9px 0px;
  display: flex;
  justify-content: flex-end;
  .deleteButton {
    border-radius: 4px;
    box-shadow: none;
    border: none;
    color: rgba(245, 54, 92, 1);
    padding: 10px 0px;
    &:hover,
    &:focus,
    &:active {
      color: rgba(245, 54, 92, 1) !important;
      outline: none !important;
      box-shadow: none !important;
      background: transparent !important;
    }
  }
  .markCompleteButton {
    box-shadow: none;
    padding: 7px;
    border-radius: 4px;
    color: #ffffff;
    border: 1px solid #e8eaef;
    background-color: #1da1f2;
    > span {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .updateButton {
    border-radius: 4px;
    box-shadow: none;
    padding: 7px;
    color: #04a0f0 !important;
    border: 1px solid #04a0f0 !important;
    > span {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .noAction {
    pointer-events: none;
  }
  .disabledOption {
    background-color: #ffffff;
    border: 1px solid #8898aa;
    color: #8898aa;
    pointer-events: none;
  }
}

.ownerShipWrapper {
  margin-top: 16px;
}

.questionContainer {
  margin-top: 24px;
  .returnTypeBtn {
    padding: 7px 15px !important;
    border-radius: 4px !important;
  }
}
.completed {
  .disableSelectedRadio {
    background-color: #525f7f !important;
    color: #ffffff !important;
    border: none !important;
    pointer-events: none;
  }
  .disableRadio {
    border: 1px solid #525f7f !important;
    color: #525f7f !important;
    pointer-events: none;
  }
  .disableTrash {
    border: 1px solid #8898aa !important;
    pointer-events: none;
    opacity: 0.5;
  }
}
.inline-group-title {
  label {
    font-size: 14px !important;
    // padding-left: 10px !important;
    margin-top: 24px !important;
  }
}

.multiGroupWrapper {
  padding-left: 50px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 24px;
    height: calc(100% - 40px);
    border-left: 2px dashed #d5d8de;
    display: flex;
  }
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 20px;
    bottom: 0;
    width: 10px;
    height: 10px;
    background-color: #32325d;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }
  .multiGroupContainer {
    position: relative;
    .multiGroup:nth-last-of-type(2) {
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        left: -30px;
        bottom: -30px;
        width: 10px;
        height: 10px;
        background-color: #32325d;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
      }
    }
    .multiGroup {
      margin: 16px 0px;
      padding: 8px;
      border-radius: 8px;
      background: #eff3f6;
      position: relative;
      .formIndex {
        margin-bottom: -12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        span {
          color: #172b4d;
          font-family: 'Open Sans';
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.4px;
        }
        .trashWrapper {
          cursor: pointer;
          border-radius: 4px;
          border: 1px solid #f5365c;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3px !important;
          position: relative;
          z-index: 5;
        }
      }
      div {
        padding-left: 0px !important;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
  .addMoreButtonWrapper {
    &.paddingTop {
      padding-top: 16px;
    }
    .addMoreButton {
      border-radius: 4px;
      border: 1px solid #2dce89;
      background: #fff;
      box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.12);
      padding: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 7px;
      &:hover {
        border-radius: 4px;
        border: 1px solid #2dce89;
        background: #fff;
        box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.12);
        padding: 7px;
      }
      img {
        width: 12px;
        height: 12px;
      }
      span {
        margin-left: 0px !important;
      }
    }
  }
}
.form_6765-elected_towards_payroll-amount_elected {
  padding-left: 40px !important;
}
.additional_information-business_income {
  padding-left: 0px !important;
}
.additional_information-taxable_income {
  padding-left: 0px !important;
}
.additional_information-control_group {
  padding-left: 0px !important;
}
.additional_information-form_4562-form_4562_exists {
  padding-left: 0px !important;
}

.additional_information-cumulative_carry_forward_losses {
  padding-left: 0px !important;
}
.schedule_c-form_6765-elected_towards_payroll-amount_elected {
  padding-left: 80px !important;
}
.general_information-end_year {
  padding-left: 12px !important;
  padding-right: 0px !important;
  :global {
    .rdtPicker {
      right: 0;
    }
  }
}
.general_information-start_year {
  padding-right: 12px !important;
}
.additional_information-form_4562-amortized_amount {
  padding-left: 40px !important;
}

.additional_information-form_4562-174_expenses_amortized {
  padding-left: 40px !important;
}

.k1_1065-k1_1065_group {
  padding-left: 0px !important;
}
