@import 'assets/scss/custom/_variables.scss';

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning !important;
}

.is-invalid {
  :global {
    .ql-toolbar {
      border: $warning 1px solid !important;
    }

    .ql-toolbar + .ql-container .ql-editor {
      border: $warning 1px solid !important;
    }
  }
}

.mention-list-container {
  z-index: 1;
  box-shadow: 0px 5px 34px rgba(0, 0, 0, 0.25);
  border-radius: 0.75rem;
  background-color: $white;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0.7rem;
  }
  &:before {
    border-bottom: 0.6rem solid rgba(0, 0, 0, 0.05);
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
  }
  &:after {
    border-bottom: 0.5rem solid #fff;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  }
  &:hover {
    &::-webkit-scrollbar {
      background-color: transparent;
      opacity: 0.9;
      width: 0.375rem;
      border-radius: 0.375rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar;
      border-radius: 0.375rem;
      transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      -webkit-transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      width: 0.375rem;
      opacity: 0.5;
    }
  }
  &::-webkit-scrollbar {
    opacity: 0;
    width: 0.375rem;
  }
  &::-webkit-scrollbar-thumb {
    opacity: 0;
    width: 0.375rem;
  }
}

.mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background: white;
  overflow-y: scroll;
  max-height: 15rem;
  min-width: 10.5rem;
  padding: 0.75rem 0rem;
  overflow-x: auto;
  &::-webkit-scrollbar {
    opacity: 0;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    opacity: 0;
    width: 4px;
  }
  :global {
    .mention-list-item.selected {
      background-color: $gray-100;
    }
  }
}

.mention-list-item {
  padding: 0.5rem;
  width: inherit;
  transition: background-color 0.2s ease;
  cursor: pointer;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background-color: $gray-100;
  }
  &:active {
    background-color: $lyght-blue !important;
    color: $white;
  }
}

:global {
  .ql-picker.ql-insertCustomTags .ql-picker-item::before {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #212519;
  }
  .ql-picker.ql-insertCustomTags .ql-picker-label::before {
    content: 'Insert variable...';
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #525f7f;
  }
  .ql-picker.ql-insertCustomTags .ql-picker-label::after {
    content: url('../../../assets/img/icons/add-icon.svg');
    position: absolute;
    right: 8px;
    top: 2px;
  }
  .ql-picker.ql-insertCustomTags .ql-picker-label {
    padding-right: 13px;
    margin-left: 10px;
  }
  .ql-picker.ql-insertCustomTags {
    .ql-picker-options {
      border-radius: 10px;
      box-shadow: 0px 50px 100px rgba(50, 50, 93, 0.1),
        0px 15px 35px rgba(50, 50, 93, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.1);
      span[data-value='hide']::before {
        display: none;
      }
      span[data-value='hide'] {
        display: none;
      }
    }
  }
  .ql-insertCustomTags .ql-picker-item:before {
    content: attr(data-label);
  }

  .ql-picker-label {
    border: 1px solid #545e7e !important;

    box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
    border-radius: 4px;
  }
  .ql-picker.ql-insertCustomTags {
    svg {
      display: none !important;
    }
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    display: none;
  }
  .ql-snow .ql-tooltip[data-mode='link']::before {
    content: '' !important;
  }
  .ql-snow .ql-tooltip.ql-editing input[type='text'] {
    width: 100% !important;
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    background-color: transparent;
    font-size: 0.875rem;
    line-height: 1.5;
    border: none;
    color: #8898aa;
  }
  .ql-tooltip:after {
    content: ' ';
    border-bottom: 0.5rem solid #fff;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  }
  .ql-snow .ql-tooltip {
    border: none !important;
  }
  .ql-snow .ql-tooltip {
    align-items: center;
  }
  .ql-snow .ql-tooltip a.ql-action::after {
    display: none;
  }
}
