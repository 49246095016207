@import 'assets/scss/custom/_variables.scss';
.partners-team {
  :global {
    .col-form-label {
      padding: 0px;
      display: flex;
      align-items: center;
      line-height: 1;
      label {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
    input.form-control {
      height: calc(1em + 1rem + 1px);
      border: 1px solid #adb5bd;
    }
    .rselect__control {
      height: 31px;
      min-height: 31px !important;
      padding: 0 5px;
      line-height: 1;
      border: 1px solid #adb5bd;
    }
    .btn-group {
      .btn {
        height: 31px;
        line-height: 0.7;
      }
    }
    .filter-col {
      justify-content: flex-end;
      padding-right: 15px;
    }
    .form-group.filter-col:after {
      content: '';
      background: #adb5bd;
      height: 34px;
      width: 2px;
      position: absolute;
      top: -1px;
    }
    .size-per-page {
      font-size: 14px;
    }
    .size-per-page-select {
      border: #dee2e6 1px solid;
      border-radius: 4px;
      font-size: 14px;
      height: 31px;
      width: 56px;
    }
    .react-bootstrap-table-page-btns-ul {
      padding-right: 0px !important;
    }
    .react-bootstrap-table-pagination-total {
      font-size: 14px;
      height: 31px;
      line-height: 33px;
    }
    .card-header {
      padding: 1.25rem 2.5rem 0rem 1.5rem !important;
    }
  }
}
