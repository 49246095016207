.formWrapper {
  max-width: 770px;
  margin-top: 24px;
  .form {
    .card {
      .cardHeader {
        h3 {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 23px;
          color: #32325d;
          margin-bottom: 0;
        }
      }
      .cardBody {
        @media (max-width: 480px) {
          padding: 8px;
        }
        h5 {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #525f7f;
          margin-bottom: 8px;
        }

        .roleSelect {
          max-width: 455px;
          margin-bottom: 24px;
          :global {
            .rselect__menu-list {
              padding: 0;
            }
          }
          .roleSwitchWarning {
            p {
              margin-bottom: 0;
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #fa3a0e;
              b {
                font-weight: 700;
              }
              strong {
                font-weight: 600;
              }
            }
          }
          .option {
            .icon {
              svg {
                path {
                  fill: #fff;
                }
              }
            }
            .role {
              overflow: hidden;
              display: flex;
              align-items: center;
              height: 100px;
              cursor: pointer;
              user-select: none;
              width: 100%;
              &.admin.active {
                border: 3px solid #525fd7;
                background-color: #f5faff;
                border-left: 1px solid #525fd7 !important;
                .tooltip {
                  svg {
                    circle {
                      fill: #525fd7;
                    }
                    path {
                      stroke: #fff !important;
                    }
                  }
                }
              }
              &.manager.active {
                border: 3px solid #34b1b9;
                background-color: #f0ffff;
                border-left: 1px solid #34b1b9 !important;
                .tooltip {
                  svg {
                    circle {
                      fill: #34b1b9;
                    }
                    path {
                      stroke: #fff !important;
                    }
                  }
                }
              }
              &.representative.active {
                border: 3px solid #d27735;
                background-color: #fff9f0;
                border-left: 1px solid #d27735 !important;
                .tooltip {
                  svg {
                    circle {
                      fill: #d27735;
                    }
                    path {
                      stroke: #fff !important;
                    }
                  }
                }
              }

              &.admin {
                .icon {
                  background-color: #525fd7;
                  svg {
                    width: 56.67px;
                    height: 68px;
                  }
                }
                .content {
                  .details {
                    h3 {
                      color: #525fd7;
                    }
                  }
                  .tooltip {
                    svg {
                      circle {
                        stroke: #525fd7;
                      }
                      path {
                        stroke: #525fd7;
                      }
                    }
                  }
                }
              }
              &.manager {
                .icon {
                  background-color: #34b1b9;
                  svg {
                    width: 56px;
                    height: 65px;
                  }
                }
                .content {
                  .details {
                    h3 {
                      color: #34b1b9;
                    }
                  }
                  .tooltip {
                    svg {
                      circle {
                        stroke: #34b1b9;
                      }
                      path {
                        stroke: #34b1b9;
                      }
                    }
                  }
                }
              }
              &.representative {
                .icon {
                  background-color: #d27735;
                  svg {
                    width: 51px;
                    height: 51px;
                  }
                }
                .content {
                  .details {
                    h3 {
                      color: #d27735;
                    }
                  }
                  .tooltip {
                    svg {
                      circle {
                        stroke: #d27735;
                      }
                      path {
                        stroke: #d27735;
                      }
                    }
                  }
                }
              }

              .icon {
                padding: 12px;
                width: 74px;
                height: 98px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  height: 38px;
                  width: 38px;
                  path {
                    fill: #fff;
                  }
                }
              }
              .content {
                display: flex;
                align-items: center;
                gap: 12.5px;
                padding: 16px;
                justify-content: space-between;
                width: 100%;
                @media (max-width: 480px) {
                  padding: 8px;
                }
                .details {
                  h3 {
                    text-transform: capitalize;
                    font-family: 'Nunito Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 8px;
                  }
                  p {
                    font-family: 'Nunito Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: #222529;
                    margin-bottom: 0;
                    word-break: break-word !important;
                  }
                }
                .tooltip {
                }
              }
            }
            &.manager {
              .icon {
                background-color: #34b1b9;
                svg {
                  width: 56px;
                  height: 65px;
                }
              }
              .content {
                .details {
                  h3 {
                    color: #34b1b9;
                  }
                }
                .tooltip {
                  svg {
                    circle {
                      stroke: #34b1b9;
                    }
                    path {
                      stroke: #34b1b9;
                    }
                  }
                }
              }
            }
            &.representative {
              .icon {
                background-color: #d27735;
                svg {
                  width: 51px;
                  height: 51px;
                }
              }
              .content {
                .details {
                  h3 {
                    color: #d27735;
                  }
                }
                .tooltip {
                  svg {
                    circle {
                      stroke: #d27735;
                    }
                    path {
                      stroke: #d27735;
                    }
                  }
                }
              }
            }
          }
          :global {
            .rselect__menu-list {
              max-height: 100%;
            }
          }
          .control {
            padding: 0rem !important;
            border-radius: 0.25rem;
            height: 100px;
            // line-height: 7rem;

            .valueContainer {
              padding: 0;
              height: 100%;
              border-radius: inherit;
              .tooltip {
                display: none;
              }
              .singleValue {
                margin: 0 !important;
                align-self: stretch !important;
                border-radius: inherit;
                height: 100%;
                white-space: normal;
              }
            }
            :global {
              .rselect__indicators {
                padding: 10px;
              }
            }
          }
        }
        .supervisor {
          h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;

            color: #525f7f;
            margin-bottom: 0;
          }
          h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;

            color: #525f7f;
          }
          .supervisorSelect {
            max-width: 345px;
            :global {
              .rselect__option--is-selected {
                background: #3c4d69;
                color: #fff;
              }
            }
          }
        }
        .allowEarningView {
          max-width: 453px;
          h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;

            color: #525f7f;
            margin-bottom: 0;
          }
          h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;

            color: #525f7f;
            margin-bottom: 8px;
          }
          .typeWrapper {
            display: flex;
            max-width: fit-content;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            margin-bottom: 1.5rem;
            overflow: hidden;
            :first-child {
              border-right: 1px solid #d9d9d9;
            }

            .type {
              padding: 8px 20px;
              cursor: pointer;
              color: #32325d;
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;

              &.active {
                color: white;
                background: #32325d;
              }
            }
          }
        }
        .statusAction {
          .statusButton {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            padding: 12px;
            text-transform: capitalize;
            color: #8498b9;
            border: 1px solid #eef0f2;
            &:disabled {
              cursor: auto !important;
              box-shadow: none !important;
            }
            &:hover {
              box-shadow: none;
            }
          }
          .Active {
            color: #ec0c38;
            &:active {
              background-color: inherit;
            }
          }
          .Deactivated {
            &:active {
              background-color: inherit;
            }
          }
        }
      }
      .cardFooter {
        border-top: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .back {
          padding: 8px 0;
          box-shadow: none !important;
          background: transparent;
          &:active {
            background-color: transparent;
          }
          &:hover {
            transform: none;
          }

          span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #4f4f4f;
            i {
              top: 0;
            }
          }
        }
        .actions {
          display: flex;
          align-items: center;
          gap: 24px;
          button {
            border: none;
            margin: 0;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            padding: 8px 16px;
          }
        }
      }
    }
  }
}

.statusChangeAction {
}

.backToEdit {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #212529;

  &:hover {
    box-shadow: none !important;
  }
}

.customDeactivateAlert {
  &:last-child > p {
    display: flex;
    justify-content: space-between !important;
    :first-child {
      background: transparent !important;
      box-shadow: none !important;
      span {
        background: transparent !important;
        box-shadow: none !important;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #212529;
        i {
          box-shadow: none !important;
        }
      }
    }
    :last-child {
      background: #fb6340;
      box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
        0px 1px 3px rgba(0, 0, 0, 0.08) !important;
      border: none;
      border-radius: 4px;
      &:active {
        background-color: #fb6340;
      }
    }
  }
}

.customActivateAlert {
  &:last-child > p {
    display: flex;
    justify-content: space-between !important;
    :first-child {
      background: transparent !important;
      box-shadow: none !important;
      span {
        background: transparent !important;
        box-shadow: none !important;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #212529;
        i {
          box-shadow: none !important;
        }
      }
    }
    :last-child {
      background: #172b4d;
      box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
        0px 1px 3px rgba(0, 0, 0, 0.08) !important;
      border-radius: 4px;
      border: none;
      &:active {
        background-color: #172b4d;
      }
    }
  }
}
