@import 'assets/scss/custom/_variables.scss';
.modalDeny {
  .title {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #32325d;
    margin-bottom: 16px;
  }

  .textOther {
    margin-top: 15px;
    padding: 15px;
    font-size: 16px;
    width: 100%;
    height: 90px;
    resize: none;
    border: 1px solid #32325d !important;
  }

  .subTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #525f7f;
    margin-bottom: 16px;
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    .deny {
      background: #dee2e6;
      border: #dee2e6;
      color: #525f7f;
    }
    .save {
      color: #fff;
      background-color: #f5365c;
      border-color: #f5365c;
      &:hover {
        color: #fff;
        background-color: #f5365c;
        border-color: #f5365c;
      }
    }

    button {
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
      border-radius: 4px;
    }
  }

  .customDropdown {
    width: 100%;
    :global {
      .dropdown-menu {
        z-index: 999999;
      }
      .dropdown-item {
        color: #525f7f;
      }
      .dropdown-item.active {
        font-weight: 600;
        color: #525f7f;
      }
    }
    button {
      margin-left: 0 !important;
      border: 1px solid #32325d !important;
      background-color: white !important;
      color: black !important;
    }

    button:first-child {
      margin-right: 1rem;
      background: #0ca8eb;
      color: #ffffff;
      border: none;
      width: 100%;
      text-align: inherit;

      @media screen and (max-width: 490px) {
        margin-bottom: 1rem;
      }
    }

    button {
      cursor: pointer;
      width: fit-content;
      padding: 13px 0;
      height: 48px;
      border: 1px solid #525f7f;
      /* Input Form Controls/Default */
      filter: drop-shadow(0px 3px 2px rgba(233, 236, 239, 0.05));
      border-radius: 4px;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      background-color: transparent;
    }
  }

  .caret {
    span {
      margin-left: 10px;
      color: #32325d;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
    .statusText {
      color: #525f7f;
    }
    i {
      color: white;
      position: absolute;
      right: -1px;
      top: -1px;
      height: 102%;
      display: flex;
      align-items: center;
      width: 30px;
      justify-content: center;
      background: #32325d;
      border-radius: 0px 4px 4px 0px;
    }
  }

  .textOther {
    margin-top: 15px;
    padding: 15px;
    font-size: 16px;
    width: 100%;
    height: 90px;
    resize: none;
  }
}
